import { useCallback, memo } from 'react';
import {
  ListItem,
  S2,
  S1,
  Grid,
  LoginAvatar,
  SecurityScoreIcon,
  AB1,
  Tooltip,
  useTranslations,
  Typography,
  S5,
} from '@uniqkey-frontend/shared-app';
import { ApplicationGetInfo } from '@uniqkey-backend-organization-web/api-client';
import { useNavigate } from 'react-router-dom';
import PageRouteEnum from '../../../../../../../../enums/PageRouteEnum';

interface IListItemContentProps extends Pick<
  ApplicationGetInfo, 'iconUrl' | 'name' | 'securityScore'
> {}

interface IDashboardMostUnsecuredServicesWidgetListItemProps {
  service: ApplicationGetInfo;
  index: number;
}

const GRID_SX = { '&:hover': { backgroundColor: AB1 } };
const TOOLTIP_SX = { flex: 1 };
const CONTAINER_HEIGHT = 48;

const ListItemContent = memo((props: IListItemContentProps) => {
  const {
    iconUrl, name, securityScore,
  } = props;

  return (
    <>
      <Grid container item xs={10.5} alignItems="center" wrap="nowrap">
        <LoginAvatar src={iconUrl} />
        <Typography variant="subtitle2" color={S5} noWrap>{name}</Typography>
      </Grid>
      <Grid item xs={1.5} alignSelf="center">
        <SecurityScoreIcon percentage={Math.floor(securityScore)} />
      </Grid>
    </>
  );
});

const DashboardMostUnsecuredServicesWidgetListItem = (
  props: IDashboardMostUnsecuredServicesWidgetListItemProps,
) => {
  const { service, index } = props;
  const {
    applicationId, iconUrl, name, securityScore,
  } = service ?? {};
  const navigate = useNavigate();
  const { t } = useTranslations();

  const handleNavigate = useCallback(() => {
    navigate(`${PageRouteEnum.Services}/${applicationId}`);
  }, [navigate, applicationId]);

  return (
    <ListItem
      sx={{ backgroundColor: index % 2 === 0 ? S2 : S1 }}
    >
      <Tooltip
        title={t('dashboardMostUnsecuredServicesWidget.viewServiceTooltip')}
        boxSX={TOOLTIP_SX}
        followCursor
        cursorPointer
      >
        <Grid
          container
          wrap="nowrap"
          justifyContent="space-between"
          overflow="hidden"
          onClick={handleNavigate}
          paddingX={3}
          paddingY={1}
          height={CONTAINER_HEIGHT}
          sx={GRID_SX}
        >
          <ListItemContent iconUrl={iconUrl} name={name} securityScore={securityScore} />
        </Grid>
      </Tooltip>
    </ListItem>
  );
};

export default memo(DashboardMostUnsecuredServicesWidgetListItem);
